import React from 'react';
import Carousel from './Carousel'

function OldProjects(){

  const galleryBoulevar = [
    '/images/proyectos/boulevard/1.JPG',
    '/images/proyectos/boulevard/2.JPG',
    '/images/proyectos/boulevard/3.JPG',
    '/images/proyectos/boulevard/4.JPG',
    '/images/proyectos/boulevard/5.JPG',
    '/images/proyectos/boulevard/6.JPG',
    '/images/proyectos/boulevard/7.JPG',
    '/images/proyectos/boulevard/8.JPG',
    '/images/proyectos/boulevard/9.JPG',
    '/images/proyectos/boulevard/10.JPG',
    '/images/proyectos/boulevard/11.JPG',
    '/images/proyectos/boulevard/12.JPG',
    '/images/proyectos/boulevard/13.JPG',
    '/images/proyectos/boulevard/14.JPG',
    '/images/proyectos/boulevard/15.jpg',
    '/images/proyectos/boulevard/16.JPG',
    '/images/proyectos/boulevard/17.JPG',
    '/images/proyectos/boulevard/18.JPG',
    '/images/proyectos/boulevard/19.JPG',
    '/images/proyectos/boulevard/20.JPG',
    '/images/proyectos/boulevard/21.JPG',
    '/images/proyectos/boulevard/22.JPG',
    '/images/proyectos/boulevard/23.JPG',
    '/images/proyectos/boulevard/24.JPG',
    '/images/proyectos/boulevard/25.JPG',
    '/images/proyectos/boulevard/26.JPG',
    '/images/proyectos/boulevard/27.JPG',
    '/images/proyectos/boulevard/28.JPG',
    '/images/proyectos/boulevard/29.JPG',
    '/images/proyectos/boulevard/30.JPG',
    '/images/proyectos/boulevard/31.JPG',
    '/images/proyectos/boulevard/33.JPG',
    '/images/proyectos/boulevard/33.JPG',
    '/images/proyectos/boulevard/34.JPG',
    '/images/proyectos/boulevard/35.JPG',
    '/images/proyectos/boulevard/36.JPG',
    '/images/proyectos/boulevard/37.JPG',
    '/images/proyectos/boulevard/38.JPG',
    '/images/proyectos/boulevard/39.JPG',
    '/images/proyectos/boulevard/40.JPG',
    '/images/proyectos/boulevard/41.JPG'
  ]
  const galleryLhep = [
    '/images/proyectos/lhep/1.JPG',
    '/images/proyectos/lhep/2.JPG',
    '/images/proyectos/lhep/3.JPG',
    '/images/proyectos/lhep/4.JPG',
    '/images/proyectos/lhep/5.JPG',
    '/images/proyectos/lhep/6.JPG',
    '/images/proyectos/lhep/7.JPG',
    '/images/proyectos/lhep/8.JPG',
    '/images/proyectos/lhep/9.JPG',
    '/images/proyectos/lhep/10.JPG',
    '/images/proyectos/lhep/11.JPG',
    '/images/proyectos/lhep/12.JPG',
    '/images/proyectos/lhep/13.JPG',
    '/images/proyectos/lhep/14.JPG',
    '/images/proyectos/lhep/15.JPG',
    '/images/proyectos/lhep/16.JPG',
    '/images/proyectos/lhep/17.JPG'
  ]
  const galleryCordiba = [
    '/images/proyectos/lares/1.jpg',
    '/images/proyectos/lares/2.jpg',
    '/images/proyectos/lares/3.jpg',
    '/images/proyectos/lares/4.jpg',
    '/images/proyectos/lares/5.jpg',
    '/images/proyectos/lares/6.jpg',
    '/images/proyectos/lares/7.jpg',
    '/images/proyectos/lares/8.jpg',
    '/images/proyectos/lares/9.jpg',
    '/images/proyectos/lares/10.jpg',
    '/images/proyectos/lares/11.jpg',
    '/images/proyectos/lares/12.jpg',
    '/images/proyectos/lares/13.jpg',
    '/images/proyectos/lares/14.jpg',
    '/images/proyectos/lares/15.jpg',
    '/images/proyectos/lares/16.jpg'
  ]

  const openPopup = (popup) => {
    document.querySelector('#popup'+popup).classList.add('active')
    document.querySelector('body').classList.add('overflow')
  } 

  const closePopup = (popup) => {
    document.querySelector('#popup'+popup).classList.remove('active')
    document.querySelector('body').classList.remove('overflow')
  }

  return(
    <>
      <div className='col-lg-4 px-lg-0'>
        <div className='item' onClick={() => openPopup(1)} data-aos="fade-right">
          <img className='item__image img-fluid' src='/images/dorika-boulevard-sarmiento-185.jpg' alt='Boulevard Sarmiento 185' title='Boulevard Sarmiento 185' />
          <div className='item__name'>
            Boulevard Sarmiento 185
          </div>
        </div>
      </div>

      <div className='col-lg-4 px-lg-0'>
        <div className='item' onClick={() => openPopup(2)} data-aos="fade-up">
          <img className='item__image img-fluid' src='/images/dorika-lhep.jpg' alt='Lhep' title='Lhep' />
          <div className='item__name'>
            Lhep
          </div>
        </div>
      </div>

      <div className='col-lg-4 px-lg-0'>
        <div className='item' onClick={() => openPopup(3)} data-aos="fade-left">
          <img className='item__image img-fluid' src='/images/dorika-lares-de-cordoba.jpg' alt='Lares de Córdoba' title='Lares de Córdoba' />
          <div className='item__name'>
            Lares de Córdoba
          </div>
        </div>
      </div> 

      {/* POPUP */}
      <div className='popup' id='popup1'>
        <div className='container'>
          <div className='row popup__content'>
            <div className='col-lg-7'>
              <div className='button-close text-right mobile' onClick={() => closePopup(1)}>
                <img src='/images/icon-close.svg' alt='Cerrar modal'  />
              </div>
              <div className='popup__content--text mobile'>
                <h4>Calidad, elegancia y buen gusto</h4>
              </div>
              <div className='divider light mobile'></div>
              <div className='popup__content--gallery'>
                <Carousel old={ true } gallery={galleryBoulevar} />
              </div>
            </div>
            <div className='col-lg-5'>
              <div className='button-close text-right desktop' onClick={() => closePopup(1)}>
                <img src='/images/icon-close.svg' alt='Cerrar modal'  />
              </div>
              <div className='popup__content--text'>
                <h4 className='desktop'>Calidad, elegancia y buen gusto</h4>
                <div className='divider light desktop'></div>
                <div className='description'>
                  <p><strong>Boulevard Sarmiento</strong> es una de las obras más emblemáticas de la Ciudad de Resistencia, finalizada en diciembre de 2012, luce imponente por su calidad y distinción.</p>
                  <p>Resultado de la visión para darle valor a un esqueleto de hormigón que decoró el centro de la ciudad durante 30 años. Ubicada a metros de la plaza principal, ya sea por su arquitectura o su distintivo diseño, BS marca un antes y después en la historia de los desarrollos inmobiliarios de la ciudad.</p>
                  <ul>
                    <li>Alta calidad de terminaciones</li>
                    <li>15 niveles</li>
                    <li>Ambientes amplio (unidades residenciales desde 190m2)</li>
                    <li>Pisos de 190 m2 y duplex de 380 m2</li>
                    <li>Ascensor principal y de servicio</li>
                    <li>Pileta ubicada en el solarium, del último piso</li>
                    <li>Salón de usos múltiples</li>
                  </ul>
                </div>
                <ul className='details'>
                  <li><strong>Dirección</strong>: Av. Sarmiento 185</li>
                  <li><strong>Ciudad</strong>: Resistencia Chaco</li>
                  <li><strong>Estado</strong>: lista para vivir</li>
                  <li><strong>Entregado</strong>: 2012</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='popup' id='popup2'>
        <div className='container'>
          <div className='row popup__content'>
            <div className='col-lg-7'>
              <div className='button-close text-right mobile' onClick={() => closePopup(2)}>
                <img src='/images/icon-close.svg' alt='Cerrar modal'  />
              </div>
              <div className='popup__content--text mobile'>
                <h4>Lhep: refugio en legua wichi</h4>
              </div>
              <div className='divider light mobile'></div>
              <div className='popup__content--gallery'>
                <Carousel old={ true } gallery={galleryLhep} />
              </div>
            </div>
            <div className='col-lg-5'>
              <div className='button-close text-right desktop' onClick={() => closePopup(2)}>
                <img src='/images/icon-close.svg' alt='Cerrar modal'  />
              </div>
              <div className='popup__content--text'>
                <h4 className='desktop'>Lhep: refugio en legua wichi</h4>
                <div className='divider light desktop'></div>
                <div className='description'>
                  <p><strong>Lhep</strong> es una excelente opción para vivir o invertir, ubicado sobre calle Ayacucho 737 en el corazón residencial de Resistencia, a metros del pulmón verde de la avenida Velez Sarfield y muy próximo a las universidades. Leph cuenta con unidades residenciales de 45 a 105 m2, sectores en terraza como lavaderos y quinchos cubiertos con parrillas.</p>
                  <p>Resultado de la visión para darle valor a un esqueleto de hormigón que decoró el centro de la ciudad durante 30 años. Ubicada a metros de la plaza principal, ya sea por su arquitectura o su distintivo diseño, BS marca un antes y después en la historia de los desarrollos inmobiliarios de la ciudad.</p>
                  <ul>
                    <li>Volumen de obra: 2000 m2</li>
                    <li>10 niveles con unidades de 1.a 3 ambientes</li>
                    <li>Local comercial en Planta Baja</li>
                    <li>Lavadero</li>
                    <li>Quinchos cubiertos con parrillas.</li>
                  </ul>
                </div>
                <ul className='details'>
                  <li><strong>Dirección</strong>: Ayacucho 737</li>
                  <li><strong>Ciudad</strong>: Resistencia Chaco</li>
                  <li><strong>Estado</strong>: lista para vivir</li>
                  <li><strong>Entregado</strong>: 2015</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='popup' id='popup3'>
        <div className='container'>
          <div className='row popup__content'>
            <div className='col-lg-7'>
              <div className='button-close text-right mobile' onClick={() => closePopup(3)}>
                <img src='/images/icon-close.svg' alt='Cerrar modal'  />
              </div>
              <div className='popup__content--text mobile'>
                <h4>Tranquilidad de estar cerca de todo</h4>
              </div>
              <div className='divider light mobile'></div>
              <div className='popup__content--gallery'>
                <Carousel old={ true } gallery={galleryCordiba} />
              </div>
            </div>
            <div className='col-lg-5'>
              <div className='button-close text-right desktop' onClick={() => closePopup(3)}>
                <img src='/images/icon-close.svg' alt='Cerrar modal'  />
              </div>
              <div className='popup__content--text'>
                <h4 className='desktop'>Tranquilidad de estar cerca de todo</h4>
                <div className='divider light desktop'></div>
                <div className='description'>
                  <p>Experimenta la sensación de estar cerca del centro de la ciudad y a unos pasos de un parque donde la naturaleza es la principal protagonista. Una zona residencial de alta demanda en los últimos tiempos y uno de los barrios más atractivos de la ciudad, hacen a <strong>Lares de Córdoba</strong> un proyecto tentador. Tanto inversores como familias pueden elegir las unidades y disfrutar de la belleza del barrio y las mejores vistas de la ciudad.</p>
                  <p>Resultado de la visión para darle valor a un esqueleto de hormigón que decoró el centro de la ciudad durante 30 años. Ubicada a metros de la plaza principal, ya sea por su arquitectura o su distintivo diseño, BS marca un antes y después en la historia de los desarrollos inmobiliarios de la ciudad.</p>
                  <ul>
                    <li>Volumen de obra: 2000 m2</li>
                    <li>10 niveles con unidades de 1.a 3 ambientes</li>
                    <li>Local comercial en Planta Baja</li>
                    <li>Lavadero</li>
                    <li>Quinchos cubiertos con parrillas.</li>
                  </ul>
                </div>
                <ul className='details'>
                  <li><strong>Dirección</strong>: Ayacucho 737</li>
                  <li><strong>Ciudad</strong>: Resistencia Chaco</li>
                  <li><strong>Estado</strong>: lista para vivir</li>
                  <li><strong>Entregado</strong>: 2018</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OldProjects;