import React from "react";

function Footer(){
  return(
    <footer className='footer'>
      <div className='footer__top'>
        <div className='container'>
          <div className='row align-items-end'> 
            <div className='col-lg-6'>
              <img className='img-fluid footer__top--logo' src='/images/logo-dorika-futuro-en-desarrollo-footer.svg' alt='Dórika' />
              <ul className='footer__top--info'>
                <li>
                  <a href='https://goo.gl/maps/5Zd674rMXxGh4v6v9' target='_blank' rel='noreferrer'>
                    <span>
                      <img className='img-fluid' src='/images/dorika-icono-mapa.svg' alt='Mapa - Calle Pellegrini 347' title='Calle Pellegrini 347' />
                    </span>
                    Calle Pellegrini 347
                  </a>
                </li>
                {/* <li>
                  <a href='5495433900' target='_blank' rel='noreferrer'>
                    <span>
                      <img className='img-fluid' src='/images/dorika-icono-telefono.svg' alt='+5495433900' title='+5495433900' />
                    </span>
                    +5495433900
                  </a>
                </li> */}
                <li>
                  <a href='https://api.whatsapp.com/send?phone=+5493624552465' target='_blank' rel='noreferrer'>
                    <span>
                      <img className='img-fluid' src='/images/dorika-icono-telefono.svg' alt='+3624552465' title='+3624552465' />
                    </span>
                    +362 4 552 465
                  </a>
                </li>
                <li>
                  <a href='mailto:dorikasrl@hotmail.com' target='_blank' rel='noreferrer'>
                    <span>
                      <img className='img-fluid' src='/images/dorika-icono-email.svg' alt='dorikasrl@hotmail.com' title='dorikasrl@hotmail.com' />
                    </span>
                    dorikasrl@hotmail.com
                  </a>
                </li>
              </ul>
            </div>
            <div className='col-lg-6'>
              <ul className='footer__top--rrss d-flex justify-content-lg-end'>
                <li>
                  <a href='https://www.facebook.com/' target='_blank' rel='noreferrer'>
                    <img className='img-fluid' src='/images/dorika-icono-facebook.svg' alt='Facebook' title='Facebook' />
                  </a>
                </li>
                <li>
                  <a href='https://www.instagram.com/' target='_blank' rel='noreferrer'>
                    <img className='img-fluid' src='/images/dorika-icono-instagram.svg' alt='Instagram' title='Instagram' />
                  </a>
                </li>
                <li>
                  <a href='https://api.whatsapp.com/send?phone=+5493624552465' target='_blank' rel='noreferrer'>
                    <img className='img-fluid' src='/images/dorika-icono-whatsapp.svg' alt='Whatsapp' title='Whatsapp' />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='footer__buttom text-center'>
        <a href='https://circulo.digital/' target='_blank' rel='noreferrer'><img className='img-fliud' src='/images/creado-por-circulo-agencia.svg' alt='Circulo Agencia' title='Creado por circulo agencia'/></a>
      </div>
    </footer>
  )
}

export default Footer;