import React from 'react';
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Carousel( { old, gallery } ) {

  if(old){
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrow: true
    };

    return(
      <>
        <Slider {...settings}>
          {
            gallery.map((item) => 
              <div className='content-slide' key={item}>
                <img className='img-fluid' src={item} alt='Boulevar Sarmiento' title='Boulevar Sarmiento'  />
              </div>
            )
          }
        </Slider>
      </>
    )

  } else {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrow: true
    };

    return(
      <>
        <div className='tag'>
          Pre-Venta
        </div>
        <Slider {...settings}>
          {
            gallery.length > 0 &&
            gallery.map((img) => 
              <div className='content-slide' key={img}>
                <div className='bg-black' style={{backgroundImage: `url(${img})`}}></div>
                <div className='bg-overlay'></div>
                <img className='img-fliud' src={`${img}`} alt='Av. Sarmiento 1166' title='Av. Sarmiento 1166'  />
              </div>
            )
          }
        </Slider>
      </>
    )
  }


}

export default Carousel;