import React from 'react';
import Carousel from './Carousel';

function ProjectRight(){

  const gallery = [
    '/images/proyectos/hernandarias/1.jpg',
    '/images/proyectos/hernandarias/2.jpg',
    '/images/proyectos/hernandarias/3.jpg',
    '/images/proyectos/hernandarias/4.jpg',
    '/images/proyectos/hernandarias/5.jpg',
    '/images/proyectos/hernandarias/6.jpg',
    '/images/proyectos/hernandarias/7.jpg',
    '/images/proyectos/hernandarias/8.jpg',
    '/images/proyectos/hernandarias/9.jpg',
    '/images/proyectos/hernandarias/10.jpg',
    '/images/proyectos/hernandarias/11.jpg'
  ]


  return(
    <>
      <div className='row project project--right flex-lg-row-reverse'>
        <div className='col-lg-4'>
          <div className='project__logo text-center'>
            <img src='/images/dorika-logo-dio-hernandarias.svg' className='img-fluid' alt='DÍO Hernandarias' title='DÍO Hernandarias' />
          </div>
          <div className='divider light'></div>
          <div className='project__text'>
            <p className='project__text--address f-light'>
              <span className='mr-2'>
                <img className='' src='/images/dorika-maps.svg' alt='icono de mapa' title='Julio A. Roca esquina Av. Hernandarias' />
              </span>
              Julio A. Roca esquina Av. Hernandarias
            </p>
            <div className='project__text--description f-light'>
              <p>Ubicado en el macrocentro de resistencia, Dío Hernandarias cuenta con <strong>10 niveles de departamentos</strong>, donde podemos encontrar departamentos tipo <strong>loft, 1 dormitorio y 2 dormitorios</strong> con superficies aproximadas que van desde 40m2 a 100 m2. Con balcones que nos brindan una espectacular vista a la ciudad.</p>
              <p>La propuesta busca la creación de espacios que mejoren la calidad de vida de sus ocupantes cuidando la armonía con el entorno y poniendo en valor la esquina en la que está emplazada, combinando la comodidad y seguridad de vivir en un departamento con la tranquilidad y confort de una casa.</p>
            </div>
          </div>
        </div>
        <div className='col-lg-8'>
          <Carousel gallery={gallery} />
        </div>
      </div>
    </>
  )
}

export default ProjectRight;

