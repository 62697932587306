import React from 'react'
import Footer from './components/layouts/Footer';
import Navbar from './components/layouts/Navbar';
import Form from './components/shared/Form';
import OldProjects from './components/shared/OldProjects';
import ProjectLeft from './components/shared/ProjectLeft';
import ProjectRight from './components/shared/ProjectRight';
import AOS from 'aos';
import 'aos/dist/aos.css';


function App() {

  AOS.init();

  return (
    <>
      <Navbar />
      <header>
        <img className='banner-image' src='/images/dorika-futuro-en-desarrollo.jpg' alt='DORIKA - Futuro en Desarrollo' title='DORIKA - Futuro en Desarrollo'  />
        <div className='banner-text d-flex align-items-center'>
          <div className='container'>
            <div className='row justify-content-center justify-content-lg-start'>
              <div className='col-md-6 text-center text-lg-left'>
                <h1 className='f-medium'>
                  <span data-aos="fade-right" data-aos-duration="1000">Futuro </span><br />
                  <span data-aos="fade-right" data-aos-duration="2000">en desarrollo</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className='nosotros' id='nosotros'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h2 className='heading--title'>Somos Dórika</h2>
              <p className='heading--subtitle f-light'>Integramos una empresa de desarrollo urbano e inmobiliario, diseño y construcción de obras edilicias destinadas a familias, privados y entes públicos del noreste argentino. Trabajamos en sinergia junto a grandes empresas desde Resistencia, Chaco. Nuestro equipo tiene la experiencia y calidad para brindar un servicio especializado adaptado a las necesidades de cada cliente. Acompañamos y concretamos los sueños de quienes quieren empezar una nueva etapa, ya que todos nuestros proyectos han sido presentados y terminados exitosamente. <br />Crecemos para ser líderes en el mercado inmobiliario, proporcionando los cimientos para el crecimiento regional con compromiso y responsabilidad tanto ambiental como social. <strong>Somos Dórika, el futuro en desarrollo</strong>.</p>
            </div>
            <div className='divider large'></div>
          </div>
        </div>
      </section>
      <section className='proyectos' id='proyectos'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h2 className='heading--title'>Proyectos en Pozo</h2>
            </div>
          </div>
          <ProjectLeft />
          <ProjectRight />
        </div>
      </section>
      <section className='proyectos-realizados pt-0'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h2 className='heading--title'>Proyectos realizados</h2>
            </div>
          </div>
          <div className='row'>
            <OldProjects />
          </div>
        </div>
      </section>
      <section className='contacto' id='contacto'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h2 className='heading--title'>Dejanos un mensaje</h2>
              <p className='heading--subtitle f-light'>Si estas pensando en tener una rentabilidad o comprar un inmueble escribinos y un asesor te contacta.</p>
            </div>
            <div className='col-lg-12'>
              <Form />
            </div>
          </div>
        </div>
        <div className='contacto__map'>
          <a href='https://goo.gl/maps/5Zd674rMXxGh4v6v9' target='_blank' rel='noreferrer'><img className='' src='/images/dorika-calle-pellegrini-347.png' alt='Mapa - Calle Pellegrini 347' title='Calle Pellegrini 347'/></a>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default App;
