import React, { useState } from 'react'

function Navbar() {

  const [link, setLink ] = useState('')

  const openMenu = () => {
    document.querySelector('.navbar-collapse').classList.toggle('active')
  }

  const handleScroll = (element) => {
    let topElem = document.getElementById(element)
    let top = topElem.offsetTop
    window.scrollTo({ top: top - 80, behavior: 'smooth' })
    setLink(element)
    openMenu()
  }

  window.onscroll = () => {
    const navbar = document.querySelector('.navbar')
    if (document.documentElement.scrollTop > 0) {
      navbar.classList.add('active')
    } else {
      if (navbar.classList.contains('active')) {
        navbar.classList.remove('active')
      }
    }

    const nosotros = document.getElementById('nosotros').offsetTop - 82;
    const proyectos = document.getElementById('proyectos').offsetTop - 82;
    const contacto = document.getElementById('contacto').offsetTop - 82;
    switch (true) {
      case document.documentElement.scrollTop < nosotros:
        setLink('')
        break;
      case document.documentElement.scrollTop > nosotros && document.documentElement.scrollTop < proyectos:
        setLink('nosotros')
        break;
      case document.documentElement.scrollTop > proyectos && document.documentElement.scrollTop < contacto:
        setLink('proyectos')
        break;
      case document.documentElement.scrollTop > contacto:
        setLink('contacto')
        break;
      default:
        break;
    }
  }

  return(
    <>
      <nav className="navbar">
        <div className='container w-100'>
          <div className='row align-items-center'>
            <div className='col-6 col-lg-3'>
              <span className="navbar-brand">
                <img src='/images/logo-dorika.svg' className='img-fluid d-block' alt='DÓRIKA - logo' title='DÓRIKA - logo' />
              </span>
            </div>
            <div className='col-6 col-lg-9 d-flex justify-content-end position-relative'>
              <button className="navbar-toggler" type="button" onClick={() => openMenu()}>
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="navbar-collapse justify-content-center justify-content-md-end">
                <ul className="navbar-nav flex-md-row">
                  <li
                    className={`${(link === 'nosotros') ? 'active' : ''} nav-item f-medium`}
                    onClick={() => handleScroll('nosotros')}
                  >
                    NOSOTROS
                  </li>
                  <li
                    className={`${(link === 'proyectos') ? 'active' : ''} nav-item f-medium`}
                    onClick={() => handleScroll('proyectos')}
                  >
                    PROYECTOS
                  </li>
                  <li
                    className={`${(link === 'contacto') ? 'active' : ''} nav-item f-medium`}
                    onClick={() => handleScroll('contacto')}
                  >
                    CONTACTO
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar;