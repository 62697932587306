import React from 'react';
import Carousel from './Carousel';

function ProjectLeft(){
  const gallery = [
    '/images/proyectos/sarmiento/1.jpg',
    '/images/proyectos/sarmiento/2.jpg',
    '/images/proyectos/sarmiento/3.jpg',
    '/images/proyectos/sarmiento/4.jpg',
    '/images/proyectos/sarmiento/5.jpg',
    '/images/proyectos/sarmiento/6.jpg',
    '/images/proyectos/sarmiento/7.jpg',
    '/images/proyectos/sarmiento/8.jpg',
    '/images/proyectos/sarmiento/9.jpg',
    '/images/proyectos/sarmiento/10.jpg',
    '/images/proyectos/sarmiento/11.jpg',
    '/images/proyectos/sarmiento/12.jpg'
  ]
  return(
    <>
      <div className='row project project--left'>
        <div className='col-lg-4'>
          <div className='project__logo text-center'>
            <img src='/images/dorika-logo-ena-sarmiento.svg' className='img-fluid' alt='ÉNA Sarmiento' title='ÉNA Sarmiento' />
          </div>
          <div className='divider light'></div>
          <div className='project__text'>
            <p className='project__text--address f-light'>
              <span className='mr-2'>
                <img className='' src='/images/dorika-maps.svg' alt='icono de mapa' title='Av. Sarmiento 1166' />
              </span>
              Av. Sarmiento 1166
            </p>
            <div className='project__text--description f-light'>
              <p>Es un edificio residencial con una combinación perfecta entre diseño arquitectónico, calidad constructiva e innovaciones tecnológicas. Cuenta con <strong>10 niveles de departamentos</strong> tipo <strong>loft, 1 dormitorio y 2 dormitorios</strong> con superficies aproximadas que van desde <strong>40m2 a 100 m2</strong>. Cada uno <strong>con balcones</strong> con una espectacular vista a la Avenida Sarmiento.</p>
              <p>Se destaca por su ubicación estratégica sobre la Av. Sarmiento, una de las más importantes de la ciudad, permitiendo conectarse con distintos puntos importantes de la capital chaqueña, por su fácil acceso a las principales avenidas, además, cerca de sanatorios, colegios, parques, shopping, centros comerciales y del edificio del Poder Judicial.</p>
            </div>
          </div>
        </div>
        <div className='col-lg-8'>
          <Carousel gallery={gallery} />
        </div>
      </div>
    </>
  )
}

export default ProjectLeft;